<div *ngIf="routerNavigationEnd$ | async">
    <ng-container *ngTemplateOutlet="(isXSmallSmall$ | async) ? mobile : laptop"> </ng-container>
</div>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>

<ng-template #mobile>
    <dsh-mobile-grid>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </dsh-mobile-grid>
</ng-template>

<ng-template #laptop>
    <dsh-laptop-grid>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </dsh-laptop-grid>
</ng-template>
